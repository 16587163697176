import React from 'react';
import styled from 'styled-components';

import Layout from '../layout/layout';
import SEO from '../components/seo';

const Address = styled.address`
  font-style: normal;
  font-size: 1.125rem;
`;

const Imprint = () => {
  return (
    <Layout>
    <SEO title="Imprint" keywords={[`demonsgalore`, `Imprint`, `Impressum`]} />
      <main>
        <h1><span>Imprint</span></h1>
        <Address>
          <strong>Andreas Stopat</strong><br />
          Fritz-Heckert-Straße 27<br />
          09557 Flöha<br />
          Deutschland
        </Address>
        <p>
          E-Mail: <a href="mailto:info@demonsgalore.de">info@demonsgalore.de</a>
        </p>
        <p>
          Telefon: <a href="tel:+4915227792330" rel="nofollow">+49 1522 779 2330</a>
        </p>
        <h3>Disclaimer</h3>
        <p>
          Inhalt und Werke dieser Website sind urheberrechtlich geschützt. Trotz
          höchster Sorgfalt kann nicht für die Richtigkeit der wiedergegebenen
          Informationen oder die permanente technische Erreichbarkeit garantiert
          werden. Es wird keine Haftung für den Inhalt von extern verlinkten
          Websites übernommen. Auf deren Inhalte haben wir keinen Einfluss und
          distanzieren uns ausdrücklich. Sollten Sie dennoch etwas an unseren
          Seiten zu beanstanden haben, bitten wir um einen einfachen
          entsprechenden Hinweis, damit wir die Inhalte schnellstmöglich entfernen
          können.
        </p>
        <p>
          Quelle: <a href="https://www.anwalt.de/vorlage/disclaimer-haftungsausschluss.php"
          target="_blank" rel="noopener noreferrer">Disclaimer von anwalt.de</a>
        </p>
      </main>
    </Layout>
  );
}

export default Imprint;
